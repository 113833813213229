export const QUEUE_INTERVAL = '500'; // 500ms

export const QUEUE_LIMIT = 100;

export const LIB = 'GIAP-javascript';

export const LIB_VERSION = '1.1.1';

export const PERSISTENCE_NAME = 'gotit.analytics.platform';

export const DISABLE_ERROR_CODE = 40101;
